import React, { useState, useEffect } from 'react';
import styles from './ListingDescription.module.scss';
import clsx from 'clsx';

const ListingDescription = ({ description }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const onResize = () => setIsShowMore(window.innerWidth < 1240);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className={styles.description}>
      <h3>Description</h3>

      <div className={clsx(
        styles.description__content,
        isShowMore ? styles[`description__content__${isOpened ? 'opened' : 'collapsed'}`] : null
      )}>
        {description.map((line, lIndex) => <p key={lIndex}>{line}</p>)}
      </div>

      {isShowMore ? (
        <a
          className={styles.description__show_more}
          onClick={() => setIsOpened(!isOpened)}
        >Show {isOpened ? 'Less' : 'More'}</a>
      ) : null}
    </div>
  );
};

export default ListingDescription;