import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './ListingVideo.module.scss';
import clsx from 'clsx';

const ListingVideo = ({ className, videoId }) => {
  const containerId = `evp-${uuidv4()}`;

  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.type = "text/javascript";
    scriptTag.src = `https://harry.evsuite.com/player/${videoId}/?responsive=1&autoResponsive=1&container=${containerId}`;

    document.getElementsByTagName("head")[0].appendChild(scriptTag);
  }, []);

  return (
    <div className={clsx(className, styles.video)}>
      <h3>Video</h3>

      <div className={styles.video__content}>
        <div id={containerId} data-role="evp-video" data-evp-id={videoId}></div>
      </div>
    </div>
  );
};

export default ListingVideo;