import React from "react";
import clsx from "clsx";
import styles from "./Company.module.scss";

const Company = () => (
  <div className={styles.company}>
    <div className={styles.company__message}>
      <label>Company Message</label>
      <p className={styles.company__message__content}>
        "Here at HK®, we believe the most valuable asset in your portfolio is
        your health. The second most valuable asset in your portfolio—if you're
        a business owner—is your business. Then of course comes quality real
        estate. Look no further."
      </p>
      <p className={styles.company__message__author}>
        <br />- Harry Kunelis | Managing Broker
      </p>
    </div>

    <div className={styles.company__office}>
      <label>Main Office</label>
      <p>
        AMA (IBM) Plaza
        <br /> 330 N. Wabash
        <br /> 23rd Floor
        <br /> Chicago, IL 60611
        <br /> Tel. 312.900.8499
      </p>
    </div>
  </div>
);

export default Company;
