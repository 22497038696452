import React, { useEffect, useState } from 'react';
import styles from './ListingSummary.module.scss';
import clsx from 'clsx';

const ListingSummary = ({ address, price, bed, bath, area, printURL, onFloorplan = () => null }) => {
  const [isXlgDevice, setIsXlgDevice] = useState(false);

  const onResize = () => setIsXlgDevice(window.innerWidth >= 1240);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className={styles.summary}>
      <div className={clsx(styles.summary__col, styles.summary__col__first)}>
        <div className={styles.summary__price}>
          <h3>${parseInt(price).toLocaleString()}</h3>
          {isXlgDevice ? <span>Price</span> : null}
        </div>

        <div className={styles.summary__bed}>
          <h3>&nbsp;{bed}&nbsp;</h3>
          <span>{isXlgDevice ? 'Bed' : 'BD'}</span>
        </div>

        <div className={styles.summary__bath}>
          <h3>&nbsp;{bath}&nbsp;</h3>
          <span>{isXlgDevice ? 'Bath' : 'BA'}</span>
        </div>

        <div className={styles.summary__area}>
          <h3>&nbsp;{area}&nbsp;</h3>
          <span>{isXlgDevice ? 'Approx. sq. ft' : 'Sqft'}</span>
        </div>

        <div className={styles.summary__address}>
          <p>{address}</p>
        </div>
      </div>

      <div className={clsx(styles.summary__col, styles.summary__col__second)}>
        <a
          className={clsx(styles.summary__button, styles.summary__button__outline)}
          onClick={onFloorplan}
        >Floorplan</a>
        <a className={styles.summary__button} href={printURL} target="__blank">Print</a>
      </div>
    </div>
  )
};

export default ListingSummary;