import React from 'react';
import styles from './ListingAgent.module.scss';
import clsx from 'clsx';

const ListingAgent = ({ className, agent, onRequestShowing = () => null }) => (
  <div className={clsx(className, styles.agent)}>
    <h3 className="d-none d-flex--md">Agent</h3>

    <div className={styles.agent__content}>
      <div className={clsx(styles.agent__content__col, styles.agent__content__col__first)}>
        <img className={styles.agent__content__photo} src={`${process.env.PUBLIC_URL}/assets/agent-photos/${agent.name}.jpg`} alt={agent.name} />
      </div>

      <div className={clsx(styles.agent__content__col, styles.agent__content__col__second)}>
        <h4>{agent.name}</h4>
        <p>{agent.license}</p>
        <p>{agent.email}</p>
        <p>{agent.phone}</p>

        <a className={clsx("d-none d-block--xxl", styles.agent__content__button)} onClick={onRequestShowing}>Request a showing</a>
      </div>

      <div className={clsx("d-none--xxl", styles.agent__content__col, styles.agent__content__col__third)}>
        <a className={styles.agent__content__button} onClick={onRequestShowing}>Request a showing</a>
      </div>
    </div>
  </div>
);

export default ListingAgent;