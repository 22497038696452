import React, { useEffect, useState } from 'react';
import styles from './RequestShowing.module.scss';
import ReactModal from 'react-modal';

ReactModal.setAppElement("#modal--request-showing");

const RequestShowing = ({ isOpen = false, onClose = () => nulll, phone = '000-000-0000' }) => {
  const [isMobile, setIsMobile] = useState(false);

  const onResize = () => setIsMobile(window.innerWidth < 768)

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <ReactModal
      isOpen={isOpen} onRequestClose={onClose} preventScroll={true}
      portalClassName={styles.modal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, .5)',
          zIndex: 10
        }
      }}
    >
      {isMobile ? (
        <>
          <h4>text or call</h4>
          <h2><a href={`tel:${phone}`}>{phone}</a></h2>
        </>
      ) : (
        <>
          <h4>please text or call</h4>
          <h2>{phone}</h2>
          <h4>to request a showing</h4>
        </>
      )}
    </ReactModal>
  );
};

export default RequestShowing;