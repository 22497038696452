import React from 'react';
import styles from './ListingLocation.module.scss';
import clsx from 'clsx';

const ListingLocation = ({ className, address }) => (
  <div className={clsx(className, styles.location)}>
    <h3>Location</h3>

    <div className={styles.location__map}>
      <iframe
        width="100%"
        height="100%"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src={`https://maps.google.com/maps?&q=${address}&output=embed`}
      />
    </div>
  </div>
);

export default ListingLocation;