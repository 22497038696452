import React, { useState } from "react";
import Header from "../../components/Header";
import Carousel from "../../components/Carousel";
import ListingSummary from "../../components/ListingSummary";
import ListingDescription from "../../components/ListingDescription";
import ListingDetails from "../../components/ListingDetails";
import ListingFeatures from "../../components/ListingFeatures";
import ListingLocation from "../../components/ListingLocation";
import ListingVideo from "../../components/ListingVideo";
import ListingAgent from "../../components/ListingAgent";
import Footer from "../../components/Footer";
import Company from "../../components/Company";
import styles from "./Listing.module.scss";
import clsx from "clsx";
import RequestShowing from "../../components/Modals/RequestShowing";
import Floorplan from "../../components/Modals/Floorplan";
import ListingPhotos from "../../components/Modals/ListingPhotos";

const Listing = ({
  listingInfo: {
    slug,
    photoCount,
    floorplanCount,
    address,
    price,
    bed,
    bath,
    area,
    description,
    details,
    features,
    videoId,
    agent,
    printURL,
  },
}) => {
  const [isMRSOpened, setIsMRSOpened] = useState(false);
  const [isMFOpened, setIsMFOpened] = useState(false);
  const [isMLPOpened, setIsMLPOpened] = useState(false);
  const [MLPIndex, setMLPIndex] = useState(0);

  const onRequestShowing = () => setIsMRSOpened(true);

  const onFloorplan = () => setIsMFOpened(true);

  const onListingPhoto = (index) => {
    setIsMLPOpened(true);
    setMLPIndex(index);
  };

  return (
    <>
      <Header
        address={address}
        onRequestShowing={onRequestShowing}
        headerType="listing"
      />

      <Carousel
        slug={slug}
        photoCount={photoCount}
        onListingPhoto={onListingPhoto}
      />

      <ListingSummary
        address={address}
        price={price}
        bed={bed}
        bath={bath}
        area={area}
        printURL={printURL}
        onFloorplan={onFloorplan}
      />

      <div className={styles.listing__content}>
        <div
          className={clsx(
            styles.listing__content__col,
            styles.listing__content__col__first
          )}
        >
          <ListingDescription description={description} />
          <ListingDetails
            className="d-block d-none--lg d-block--xlg"
            details={details}
          />
          <ListingFeatures features={features} />
        </div>

        <div
          className={clsx(
            styles.listing__content__col,
            styles.listing__content__col__second
          )}
        >
          <ListingAgent
            className="d-none d-block--lg"
            agent={agent}
            onRequestShowing={onRequestShowing}
          />
          <ListingDetails
            className="d-none d-block--lg d-none--xlg"
            details={details}
          />
          <ListingLocation className="d-none d-block--xlg" address={address} />
          <ListingVideo className="d-none d-block--xlg" videoId={videoId} />
        </div>

        <div
          className={clsx(
            styles.listing__content__col,
            styles.listing__content__col__third
          )}
        >
          <ListingLocation address={address} />
          <ListingVideo videoId={videoId} />
          <ListingAgent
            className="d-none--lg"
            agent={agent}
            onRequestShowing={onRequestShowing}
          />
        </div>
      </div>

      <Company />

      <Footer />

      <RequestShowing
        isOpen={isMRSOpened}
        onClose={() => setIsMRSOpened(false)}
        phone={agent?.phone}
      />
      <Floorplan
        isOpen={isMFOpened}
        onClose={() => setIsMFOpened(false)}
        slug={slug}
        floorplanCount={floorplanCount}
      />
      <ListingPhotos
        isOpen={isMLPOpened}
        onClose={() => setIsMLPOpened(false)}
        slug={slug}
        photoCount={photoCount}
        activeIndex={MLPIndex}
      />
    </>
  );
};

export default Listing;
