import React from 'react';
import styles from './Floorplan.module.scss';
import ReactModal from 'react-modal';
import Slider from "react-slick";
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import clsx from 'clsx';

ReactModal.setAppElement("#modal--floorplan");

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={clsx(styles.carousel__arrow, styles.carousel__arrow__prev)}
    aria-hidden="true"
    type="button"
  ><FiChevronLeft /></button>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={clsx(styles.carousel__arrow, styles.carousel__arrow__next)}
    aria-hidden="true"
    type="button"
  ><FiChevronRight /></button>
);

const slickOptions = {
  infinite: true,
  arrows: true,
  variableWidth: false,
  prevArrow: <SlickArrowLeft />,
  nextArrow: <SlickArrowRight />
};

const Floorplan = ({ isOpen = false, onClose = () => null, slug, floorplanCount }) => {
  return (
    <>
      <ReactModal
        isOpen={isOpen} onRequestClose={onClose} preventScroll={true}
        portalClassName={styles.modal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, .5)',
            zIndex: 10
          }
        }}
      >
        <div className={styles.carousel}>
          <Slider {...slickOptions}>
            {Array.from(Array(floorplanCount).keys()).map(fIndex => (
              <div className={styles.carousel__item} key={fIndex}>
                <img src={`${process.env.PUBLIC_URL}/assets/floorplan-photos/${slug}/floorplan-photo (${fIndex + 1}).jpg`} />
              </div>
            ))}
          </Slider>
        </div>
      </ReactModal>
    </>
  );
};

export default Floorplan;