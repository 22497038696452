import React from 'react';
import styles from './ListingDetails.module.scss';
import clsx from 'clsx';

const ListingDetails = ({ className, details }) => (
  <div className={clsx(className, styles.details)}>
    <h3>Details</h3>

    <div className={styles.details__content}>
      {Object.keys(details).map(key => <p key={key}>{key}: <span>{details[key]}</span></p>)}
    </div>
  </div>
);

export default ListingDetails;