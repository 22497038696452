import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from "./PrivacyPolicy.module.scss";
import RequestShowing from "../../components/Modals/RequestShowing";

const PrivacyPolicy = () => {
  const [isCallOpened, setIsCallOpened] = useState(false);

  const onRequestShowing = () => setIsCallOpened(true);

  return (
    <>
      <Header onRequestShowing={onRequestShowing} />

      <div className={styles.page}>
        <div className={styles.privacy}>
          <h1>Privacy Policy</h1>
          <p>
            We collect information to provide better services to all our users.
            When you use our services, you’re trusting us with your information.
            Which is why we work hard to protect your information. We use
            various technologies to collect information when you visit our
            website, and this may include cookies or similar technology which
            may uniquely identify your browser. You may set your browser to
            block all cookies. However, it is important to remember that many of
            our user experience features may not function properly if cookies
            are disabled. We do not share your personal information with outside
            organizations without your consent. We may share non-personally
            identifiable, and personally identifiable, information with our
            connected sites for the general use of our services. We work hard to
            protect our users by encrypting many of our services with SSL
            (secure socket layer). Above all, we recognize the importance to
            stay safe and secure online.
          </p>
        </div>

        <div className={styles.cookies}>
          <h1>What are cookies</h1>
          <p>
            Cookies are small pieces of text used to store information on web
            browsers. Cookies are used to store and receive identifiers and
            other information on computers, phones, and other devices. Other
            technologies, data we store, and identifiers associated with your
            device are used for similar purposes. We use cookies to help us show
            relevant ads and to help us measure the performance of ad campaigns.
            We also use cookies to help provide content relevant to you and to
            enhance your user experience. If perhaps you have an account with
            us, we use cookies to help keep your account safe and secure—because
            cookies can help us identify and impose additional security measures
            when someone may be attempting to access an account without
            authorization, for instance, when hackers rapidly guess different
            passwords. If you have a question about this privacy policy, feel
            free to contact us.
          </p>
        </div>
      </div>

      <Footer />

      <RequestShowing
        isOpen={isCallOpened}
        onClose={() => setIsCallOpened(false)}
        phone="312-900-8499"
      />
    </>
  );
};

export default PrivacyPolicy;
