import React, { useState } from "react";
import styles from "./FairHousing.module.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import imgSrc from "../../assets/images/Fair Housing.png";
import RequestShowing from "../../components/Modals/RequestShowing";

const FairHousing = () => {
  const [isCallOpened, setIsCallOpened] = useState(false);

  const onRequestShowing = () => setIsCallOpened(true);

  return (
    <>
      <Header onRequestShowing={onRequestShowing} />

      <div className={styles.page}>
        <div className={styles.page__image}>
          <h1>Fair Housing Policy</h1>
          <img src={imgSrc} alt="Fair Housing" />
        </div>

        <div className={styles.page__content}>
          <h1>Fair Housing Policy</h1>
          <h3>Equal Housing Opportunity Statement:</h3>
          <p>
            We are pledged to the letter and spirit of U.S. policy for the
            achievement of equal housing opportunity throughout the Nation. We
            encourage and support an affirmative advertising and marketing
            program in which there are no barriers to obtaining housing because
            of race, color, religion, sex, handicap, familial status, or
            national origin.
          </p>
        </div>
      </div>

      <Footer />

      <RequestShowing
        isOpen={isCallOpened}
        onClose={() => setIsCallOpened(false)}
        phone="312-900-8499"
      />
    </>
  );
};

export default FairHousing;
