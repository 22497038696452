import React, { useEffect, useState, useRef } from "react";
import styles from "./Home.module.scss";
import clsx from "clsx";
import { Link, useHistory, useLocation } from "react-router-dom";
const FAQs = require("./FAQ.json");
import GetInTouch from "../../components/Modals/GetInTouch";

const Home = () => {
  const history = useHistory();
  const [faqIndex, setFAQIndex] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const getInTouchRef = useRef(null);
  const { state } = useLocation();

  const handleScrollToGetInTouch = (e) => {
    e?.preventDefault();

    window.scrollTo({
      left: 0,
      top: getInTouchRef.current.offsetTop - 50,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (state?.scrollTo === "get-in-touch") handleScrollToGetInTouch();
    else window.scrollTo(0, 0);
  }, [state]);

  return (
    <>
      <div className={styles.page_home}>
        <div className={styles.header}>
          <div className={styles.logo} onClick={() => history.push("/")}>
            <img src="/HKLuxuryRealtyLogo.svg" alt="HKLuxuryRealtyLogo" />

            <div className={styles.logo__text}>
              <span>Luxury Realty</span>
              <span>Get Smarter Faster®</span>
            </div>
          </div>

          <button onClick={() => handleScrollToGetInTouch()}>Contact Us</button>
        </div>

        <div className={styles.hero}>
          <div
            className={clsx(styles.logo, styles.hero__logo)}
            onClick={() => history.push("/")}
          >
            <img src="/HKLuxuryRealtyLogo.svg" alt="HKLuxuryRealtyLogo" />

            <div className={styles.logo__text}>
              <span>Luxury Realty</span>
              <span>Get Smarter Faster®</span>
            </div>
          </div>

          <div className={styles.hero__services}>
            <h1>Chicago luxury home services</h1>
            <p>
              Get smarter faster® when you buy. Sell with Ai digital
              <br /> marketing. All so you can maximize your wealth...
            </p>
          </div>

          <div className={styles.hero__button__groups}>
            <a
              className={styles.hero__button}
              href="https://www.hkbuyproperty.com"
            >
              Search Listings
            </a>
            <a
              className={clsx(styles.hero__button, styles.hero__button__black)}
              href="https://www.hksellproperty.com/"
            >
              Sell Property
            </a>
          </div>
        </div>

        <div className={styles.button__group}>
          <a className={styles.button} href="https://www.hkbuyproperty.com">
            Search Listings
          </a>
          <a
            className={clsx(styles.button, styles.button__black)}
            href="https://www.hksellproperty.com/"
          >
            Sell Property
          </a>
        </div>

        <div className={clsx(styles.SBR, styles.SBR__selling)}>
          <p>
            If you wish to hire an agency that utilizes modern day marketing
            strategies, then look no further. Nowadays, everything has gone
            digital. And rightfully so, considering you can target your perfect
            prospect more effectively. HK® Luxury Realty is the 1st brokerage in
            downtown Chicago to harness Ai and identity resolution marketing
            which means we know who the home buyers in your area are—before
            anyone else.
          </p>
        </div>

        <div className={clsx(styles.SBR, styles.SBR__buying)}>
          <p>
            Budgets of all shapes and sizes are welcome here. You can save
            thousands of dollars when you choose to buy Chicago luxury real
            estate here, because our HK® Value Analysis is the only method on
            the market for property buyers to cross-check home prices against
            three proven pricing metrics. So you can Get Smarter Faster® and
            pinpoint hidden bargains even if prices are really skyrocketing.
          </p>
        </div>

        <div className={clsx(styles.SBR, styles.SBR__renting)}>
          <p>
            Sometimes it's just better to rent. And we know you can't put good
            folks in bad properties. Therefore, we vet property with quality
            attributes as much as we validate tenant worthiness. Oftentimes,
            tenants can comfortably afford to buy, but choose to rent for
            strategic reasons instead. Creating an alignment of interests
            between a landlord and a tenant is the key—that's our faithful
            focus.
          </p>
        </div>

        <div className={styles.where_we_play}>
          <h1>Where we Play</h1>
          <p>
            We maintain razor sharp focus in servicing the following downtown
            Chicago areas: River North, Gold Coast, Magnificent Mile,
            Streeterville, LakeShore East, the Loop, West Loop, South Loop,
            Printer's Row, Randolph Market, Fulton Market, Greek Town, Museum
            Park, Prairie District, Old Town, East Lakeview and Lincoln Park.
          </p>
        </div>

        <div className={styles.map} />

        <div className={styles.about}>
          <div className={styles.about__image_wrapper}>
            <img
              src="/assets/agent-photos/Harry Kunelis.jpg"
              alt="Harry Kunelis"
            />
            <p>Managing Broker</p>
          </div>

          <div className={styles.about__content_wrapper}>
            <h1>About Our Credo</h1>
            <p>
              Here at HK®, we believe the most valuable asset in your portfolio
              is your health. The second most valuable asset in your
              portfolio—if you're a business owner—is your business. And then of
              course, comes quality real estate. Look no further.
              <br />
              <br />— Harry Kunelis, DDS
            </p>
          </div>
        </div>

        <div className={styles.FAQ}>
          <h1>Frequently Asked Questions</h1>

          <div className={styles.FAQ__inner_wrapper}>
            <div className={styles.FAQ__col}>
              {FAQs.map(({ question, answer }, index) =>
                index < 6 ? (
                  <div
                    key={index}
                    className={clsx(
                      styles.FAQ__item,
                      faqIndex === index && styles.FAQ__item__opened
                    )}
                  >
                    <h3
                      onClick={() =>
                        setFAQIndex(index === faqIndex ? null : index)
                      }
                    >
                      {question}
                    </h3>

                    <p
                      className={clsx(
                        styles.FAQ__item__desc,
                        faqIndex === index && styles.FAQ__item__desc__opened
                      )}
                      dangerouslySetInnerHTML={{ __html: answer }}
                    />
                  </div>
                ) : null
              )}
            </div>

            <div className={styles.FAQ__col}>
              {FAQs.map(({ question, answer }, index) =>
                index < 6 ? null : (
                  <div
                    key={index}
                    className={clsx(
                      styles.FAQ__item,
                      faqIndex === index && styles.FAQ__item__opened
                    )}
                  >
                    <h3
                      onClick={() =>
                        setFAQIndex(index === faqIndex ? null : index)
                      }
                    >
                      {question}
                    </h3>

                    <p
                      className={clsx(
                        styles.FAQ__item__desc,
                        faqIndex === index && styles.FAQ__item__desc__opened
                      )}
                      dangerouslySetInnerHTML={{ __html: answer }}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        <div className={styles.get_in_touch} ref={getInTouchRef}>
          <div className={styles.get_in_touch__col}>
            <button onClick={() => setModalOpened(true)}>Get in Touch</button>
            <p>
              Contact us about getting world-class downtown Chicago luxury real
              estate services. And even leverage the #1 real estate marketing
              technology in America while you're at. Please provide a message
              below and tell us a little bit about your objective or challenge.
              And we will contact you within 24hrs. Normally less.
            </p>
          </div>

          <div className={styles.get_in_touch__col}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.6496059421474!2d-87.63806678455886!3d41.87888467922222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0:0x33e31333268d47e7!2sHK+Luxury+Realty+Inc.!5e0!3m2!1sen!2sus!4v1509336451131"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <div className={styles.contact__wrapper}>
          <span>
            HK Luxury
            <br />
            Realty, Inc.
          </span>
          <div className={styles.contact}>
            <p>
              <span>HK Luxury Realty, Inc.</span>
              AMA (IBM) Plaza
              <br />
              330 N. Wabash
              <br />
              23rd Floor
              <br />
              Chicago, IL 60611
              <br />
              <a href="tel:3128009019">312-800-9019</a>
            </p>
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.footer__col}>
            © 2021 HK® Luxury Realty - Get Smarter Faster®
            <span>. All rights reserved</span>
            <div>
              <span>All rights reserved.</span>
              <Link to="/privacy-policy">Privacy</Link>
            </div>
          </div>

          <div className={styles.footer__col}>
            <Link to="/">About</Link>
            <a href="https://www.hksellproperty.com">Sell</a>
            <a href="https://www.hkbuyproperty.com">For Sale</a>
            <Link to="/fair-housing">Fair Housing</Link>
            <Link to="/privacy-policy">Privacy</Link>
            <Link to={{ pathname: "/", state: { scrollTo: "get-in-touch" } }}>
              Contact
            </Link>
          </div>
        </div>
      </div>

      <GetInTouch isOpen={modalOpened} onClose={() => setModalOpened(false)} />
    </>
  );
};

export default Home;
