import React, { useEffect, useState } from "react";
import styles from "./GetInTouch.module.scss";
import ReactModal from "react-modal";

ReactModal.setAppElement("#modal--get-in-touch");

const GetInTouch = ({
  isOpen = false,
  onClose = () => nulll,
  email = "support@hkluxuryrealty.com",
}) => {
  const [isMobile, setIsMobile] = useState(false);

  const onResize = () => setIsMobile(window.innerWidth < 768);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      preventScroll={true}
      portalClassName={styles.modal}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, .5)",
          zIndex: 10,
        },
      }}
    >
      <h4>please email</h4>
      <h4>
        <a href={`mailto:${email}`}>{email}</a>
      </h4>
    </ReactModal>
  );
};

export default GetInTouch;
