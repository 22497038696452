import React, { useState } from 'react';
import styles from './ListingPhotos.module.scss';
import ReactModal from 'react-modal';
import ImageGallery from 'react-image-gallery';
import { FiX } from 'react-icons/fi';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs';
import clsx from 'clsx';

const ListingPhotos = ({ isOpen = false, onClose = () => null, slug, photoCount, activeIndex = 3 }) => {
  const images = Array.from(Array(photoCount).keys()).map(pIndex => ({
    original: `${process.env.PUBLIC_URL}/assets/listing-photos/${slug}/listing-photo (${pIndex + 1}).jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/listing-photos/${slug}/listing-photo (${pIndex + 1}).jpg`
  }));
  const [isFullscreen, setIsFullscreen] = useState(true);

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onClose}
        portalClassName={styles.modal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, .5)',
            zIndex: 10
          }
        }}
      >
        <button className={clsx("image-gallery-icon", styles.close)}
          onClick={() => {
            setIsFullscreen(true);
            onClose();
          }}
          type="button"
        ><FiX /></button>
        <div className={isFullscreen ? 'fullscreen' : ''}>
          <ImageGallery
            startIndex={activeIndex}
            items={images} showPlayButton={false} lazyLoad={true}
            renderFullscreenButton={() => (
              <button
                type='button'
                className="image-gallery-icon image-gallery-fullscreen-button"
                onClick={() => setIsFullscreen(!isFullscreen)}
              >
                {isFullscreen ? <BsFullscreenExit className="image-gallery-svg" /> : <BsFullscreen className="image-gallery-svg" />}
              </button>
            )}
          />
        </div>
      </ReactModal>
    </>
  );
};

export default ListingPhotos;