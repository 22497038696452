import React from "react";
import styles from "./Footer.module.scss";
import clsx from "clsx";
import { Link } from "react-router-dom";

const Footer = () => (
  <div className={styles.footer}>
    <div className={clsx(styles.footer__navs, styles.footer__navs__mobile)}>
      <Link to="/privacy-policy">Privacy</Link>
      <Link to="/fair-housing">Fair Housing</Link>
      <Link to={{ pathname: "/", state: { scrollTo: "get-in-touch" } }}>
        Contact
      </Link>
    </div>

    <div className={styles.footer__copy_right}>
      &copy;&nbsp;{new Date().getFullYear()}&nbsp;HK&reg; Luxury Realty - Get
      Smarter Faster&reg;
      <br />
      <span> - </span>All rights reserved.
    </div>

    <div className={clsx(styles.footer__navs, styles.footer__navs__desktop)}>
      <Link to="/fair-housing">Fair Housing</Link>
      <Link to="/privacy-policy">Privacy</Link>
      <Link to={{ pathname: "/", state: { scrollTo: "get-in-touch" } }}>
        Contact
      </Link>
    </div>
  </div>
);

export default Footer;
