import React, { useState, useEffect } from 'react';
import styles from './ListingFeatures.module.scss';
import { FaCircle } from 'react-icons/fa';
import clsx from 'clsx';

const ListingFeatures = ({ features }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const onResize = () => setIsShowMore(window.innerWidth < 1240);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className={styles.features}>
      <h3>Features</h3>

      <div className={clsx(
        styles.features__content,
        isShowMore ? styles[`features__content__${isOpened ? 'opened' : 'collapsed'}`] : null
      )}>
        {features.map((feature, lIndex) => <p key={lIndex}><span><FaCircle />&nbsp;</span>&nbsp;{feature}</p>)}
      </div>

      {isShowMore ? (
        <a
          className={styles.features__show_more}
          onClick={() => setIsOpened(!isOpened)}
        >Show {isOpened ? 'Less' : 'More'}</a>
      ) : null}
    </div>
  );
};

export default ListingFeatures;