import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import Listing from './pages/Listing';
import Home from './pages/Home';
import FairHousing from './pages/FairHousing';
import PrivacyPolicy from './pages/PrivacyPolicy';

const listings = require('./listings.json');

const Routes = () => {
  const { pathname } = useLocation();

  useEffect(() => pathname !== '/' ? window.scrollTo(0, 0) : null, [pathname]);

  return (
    <>
      <Route exact
        path={`${process.env.PUBLIC_URL}/`}
        component={Home}
      />

      <Route exact
        path={`${process.env.PUBLIC_URL}/fair-housing`}
        component={FairHousing}
      />


      <Route exact
        path={`${process.env.PUBLIC_URL}/privacy-policy`}
        component={PrivacyPolicy}
      />

      {listings.map(({ path, listingInfo }, index) => (
        <Route exact
          key={index}
          path={`${process.env.PUBLIC_URL}/${path}`}
          component={() => <Listing listingInfo={listingInfo} />}
        />
      ))}
    </>
  );
};

const App = () => (
  <BrowserRouter basename={'/'}>
    <Switch>
      <Routes />
    </Switch>
  </BrowserRouter>
);

export default App;