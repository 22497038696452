import React from "react";
import styles from "./Header.module.scss";
import { FiMenu } from "react-icons/fi";
import clsx from "clsx";
import { Link } from "react-router-dom";

const ListingHeader = ({ address, onRequestShowing = () => null }) => (
  <div className={styles.header}>
    <a className={styles.header__menu} onClick={onRequestShowing}>
      <FiMenu />
    </a>
    <div className={styles.header__address}>
      <h5>For Sale</h5>
      <p>{address}</p>
    </div>

    <Link className={styles.header__logo} to="/">
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
        alt="HK Luxury Realty"
      />
    </Link>

    <a
      className={clsx(styles.header__button, styles.header__button_tour)}
      onClick={onRequestShowing}
    >
      Tour
    </a>
    <a
      className={clsx(styles.header__button, styles.header__button_request)}
      onClick={onRequestShowing}
    >
      Request Showing
    </a>
  </div>
);

const Header = ({ onRequestShowing = () => null }) => (
  <div className={styles.header}>
    <a className={styles.header__menu} onClick={onRequestShowing}>
      <FiMenu />
    </a>

    <Link className={styles.header__logo} to="/">
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
        alt="HK Luxury Realty"
      />
    </Link>

    <a
      className={clsx(styles.header__button, styles.header__button_tour)}
      onClick={onRequestShowing}
    >
      Call
    </a>
  </div>
);

export default (props) => {
  if (props.headerType === "listing") return <ListingHeader {...props} />;

  return <Header {...props} />;
};
