import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import styles from './Carousel.module.scss';
import clsx from 'clsx';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={clsx(styles.carousel__arrow, styles.carousel__arrow__prev)}
    aria-hidden="true"
    type="button"
  ><FiChevronLeft /></button>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={clsx(styles.carousel__arrow, styles.carousel__arrow__next)}
    aria-hidden="true"
    type="button"
  ><FiChevronRight /></button>
);

const slickOptions = {
  infinite: true,
  arrows: true,
  variableWidth: true,
  prevArrow: <SlickArrowLeft />,
  nextArrow: <SlickArrowRight />
};

const Carousel = ({ slug, photoCount, onListingPhoto = () => null }) => {
  const [isDesktop, setIsDesktop] = useState(false);

  const onResize = () => setIsDesktop(window.innerWidth >= 1240);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className={styles.carousel}>
      <Slider {...slickOptions} swipe={!isDesktop}>
        {Array.from(Array(photoCount).keys()).map(pIndex => (
          <div
            className={styles.carousel__item} key={pIndex}
            onClick={() => isDesktop ? onListingPhoto(pIndex) : null}
          >
            <img src={`${process.env.PUBLIC_URL}/assets/listing-photos/${slug}/listing-photo (${pIndex + 1}).jpg`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;